<template>
  <!-- 
    画面: 問合せ一覧
    用途: 利用者からの問合せの一覧を参照する
   -->
  <div>

    <!-- #region TODO -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            問合せ一覧
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            <ul>
              <li>問合せを一覧表示できること</li>
              <li>一覧を絞り込みできること</li>
              <li>問合せ詳細画面へのリンクがあること</li>
              <li>問合せ詳細画面で問合せへ返信できること</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion TODO -->

  </div>
</template>

<script>
export default {
}
</script>
